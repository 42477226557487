$black: #171717;
$white: #FBF5F5;
$softwhite: #fbf5f599;

@font-face {
  font-family: "EikoThin";
  src: url("./assets/fonts/Eiko/PPEiko-Thin.ttf") format("truetype"),
  url("./assets/fonts/Eiko/PPEiko-Thin.woff") format("woff"),
  url("./assets/fonts/Eiko/PPEiko-Thin.woff2") format("woff2"),
  url("./assets/fonts/Eiko/PPEiko-Thin.otf") format("opentype");
}

* { 
  padding: 0; 
  margin: 0; 
  box-sizing: border-box;
} 

html, body {
  background-color: $black;
}

#overflow {
  overflow: hidden;
  position: relative;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: $black;
}

::-webkit-scrollbar-button {                
  height: 0;
  width: 0;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: $white;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $softwhite;
}


