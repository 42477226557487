@use '../index';

.sidebarWrapper {
    position: fixed;
    height: 100vh;
    width: 130px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.sidebarNavWrapper {
    
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sidebarBorder {
    height: 100vh;
    margin-top: -100vh;
    z-index: -2;
}
.borderBase {
    height: 0;
    border-left: 2px index.$softwhite dotted;
    position: fixed;
}
.borderOverlay {
    height: 0;
    position: fixed;
}
.borderSquare {
    width: 10px;
    height: 10px;
    transform-origin: top left;
    transform: rotate(45deg) translate(-4.5px,-6px);
    background-color: index.$black;
    border: 2px solid index.$white;
}
.borderHighlight {
    height: 100%;
    border-left: 2px index.$white solid;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: index.$white;
    font-family: "EikoThin";
    
    height: 50%;
    margin-left: 30px;
}

.logo {
    height: 75px;
    width: 75px;
    border-style: solid;
    border-color: index.$black;
    border-width: 12px 15px 15px 15px;
    margin-left: -37.5px;
    margin-bottom: calc(100vh - 300px);
    background-image: url("../assets/svgs/Logo.svg");
    background-color: index.$black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    cursor: pointer;
}

.sidebarItem {
    opacity: 0%;
    font-size: 10pt;
    cursor: pointer;
    user-select: none;
}