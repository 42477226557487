@use '../index';



.landing{
  display: flex;
  width: 100vw;
  height:100vh;
  justify-content: center;
}

.delete{
  margin:0;
  padding: 0;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border-width: 0;
  top:calc(50vh - 50px);
  left:calc(50vw - 50px);
  cursor: pointer;
  z-index: 5;
  background-color: transparent;
  color: index.$white;
  position:absolute;
  display: flex;
  align-items:center;
  justify-content:center;
  font-family: "EikoThin";
  user-select: none;
}

.circle {
  position: absolute;
  border-radius: 100%;
}
.circleMask{
  background: index.$black;
}

.name{
  position:absolute;
  height:100vh;
  width:100vw;
  display: flex;
  align-items:flex-end;
  justify-content:center;
  padding-bottom: 40px;
  color: index.$white;
  z-index: 4;
  font-family: "EikoThin";
  font-size: calc(14pt + 0.1vw);
  
  text-align: center;
  background-image: url("../assets/svgs/MyName.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gradient{
  height: 250px;
  width: 250px;
  top: calc(50vh - 125px);
  left: calc(50vw - 125px);
  border-radius: 100%;
  background: radial-gradient(index.$black 30%, transparent 80%);
  z-index:3;
  position:absolute;
}

.line{
  position: absolute;
  width: 12px;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0 solid index.$white;
  top:50vh;
  left:calc(50vw - 6px);
  transform-origin: 6px 0;
  z-index:2;
}

.wiper{
  position: absolute;
  top:calc(50vh - 100vmax);
  left:calc(50vw - 100vmax);
  height:200vmax;
  width:200vmax;
  z-index:2;
}

.wiperLeft{
  stroke:index.$white;
  stroke-width: 100vmax;
  fill:none;
}

.wiper:hover .wiperLeft{
  stroke-dashoffset: 314;
}

.shakingLeft {
  animation: shake1 1s infinite;
}
.shakingRight {
  animation: shake2 1s infinite;
}