@use '../index';

.headerWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    position: fixed;
}

.header {
    color: index.$white;
    font-family: "EikoThin";
    font-size: 14pt;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    height: 80px;
}

.headerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: 15px;
    opacity: 0;
}

.itemText {
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
}

.itemBorder{
    border-bottom: 2px solid index.$softwhite;
    margin-top: 5px;
    transition: all 0.2s;
}

.headerBorder {
    position: fixed;
    width: 0;
    height: 80px;
    border-bottom: 2px solid index.$softwhite;
    z-index: -1;
    margin-left: -100%;
    margin-top: 5px;
}

.accentSquare {
    width: 10px;
    height: 10px;
    transform-origin: top left;
    background-color: index.$black;
    border-bottom: 2px solid index.$softwhite;
    border-right: 2px solid index.$softwhite;
    transition: all 0.2s;
}

